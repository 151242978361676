import Swap from "./Swap";
import Home from "./landing/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react"


function App() {

  return (
    <div className="App">
      <Analytics />
        <Router>
        <Routes>
             <Route path="/" element={<Home />} />
              <Route path="/swap" element={<Swap />} />
        </Routes>
        </Router>
    </div>
  );
}

export default App;
