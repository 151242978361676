import React from 'react';
import { Canvas, useFrame } from 'react-three-fiber';
import * as THREE from 'three';

const Particle = ({ position, color, speed }) => {
  const particleRef = React.useRef();

  useFrame(() => {
    // Update particle position based on speed
    particleRef.current.position.y += speed;
    if (particleRef.current.position.y > 10) {
      particleRef.current.position.y = -10; // Reset position if particle goes out of view
    }
  });

  return (
    <mesh ref={particleRef} position={position}>
      <sphereGeometry args={[0.01, 16, 16]} /> {/* Smaller particle size (0.01 units in diameter) */}
      <meshBasicMaterial color={color} />
    </mesh>
  );
};

const QuantumParticlesBackground = () => {
  const particleCount = 500; // Increase particle count for denser background
  const particles = [];

  // Generate random positions, colors, and speeds for particles
  for (let i = 0; i < particleCount; i++) {
    particles.push({
      id: i,
      position: [Math.random() * 20 - 10, Math.random() * 20 - 10, Math.random() * 20 - 10],
      color: Math.random() * 0xffffff,
      speed: Math.random() * 0.02 + 0.01, // Random speed within range
    });
  }

  return (
    <Canvas camera={{ position: [0, 0, 15] }}>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />

      {/* Render particles */}
      {particles.map((particle) => (
        <Particle key={particle.id} position={particle.position} color={particle.color} speed={particle.speed} />
      ))}
    </Canvas>
  );
};

export default QuantumParticlesBackground;
