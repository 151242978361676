// ChooseTokenModal.js

import React, { useState, useEffect } from 'react';
import '../styles/modalStyles.css';
import qanToken from '../images/qanBlackLogo.png';
import Dog from '../images/Dog.jpg';
import Frog from '../images/Frog.jpg';
import Kangaroo from '../images/Kangaroo.jpg';
import Spider from '../images/Spider.jpg';
import questionToken from "../images/question-icon.png";
import getTokenInfo from '../utils/functions';

const tokenList = [
  { name: 'tQANX', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', img: qanToken },
  { name: 'DOG', address: '0x23c014E34b5213183343d1858Ff9446265F53D33', img: Dog  },
  { name: 'SpR', address: '0xc4BE4f8Aca802D7be3012419171436Fa67fC41c3', img: Spider  },
  { name: 'Kangaroo', address: '0xB105160fD6BdAe72beD1109E74Bb8A643124Bb8f', img: Kangaroo  },
  { name: 'FROG', address: '0x9Afa60cd312758dD978CBA682558d037a25e193A', img: Frog  },


  // Add more tokens as needed
];

const ChooseTokenModal = ({ isOpen, closeModal, setTokenContractAddress, setTokenName, currentSelectedToken, selectedTokenInOtherField, handleSwapTokens,clearTokenValues, verifiedTokenInfo, setVerifiedTokenInfo }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [matchingTokens, setMatchingTokens] = useState(tokenList);
  const [localError, setLocalError] = useState('');
  const [recentlyUsedTokens, setRecentlyUsedTokens] = useState([]);

  useEffect(() => {
    const storedRecentlyUsedTokens = JSON.parse(localStorage.getItem('recentTokens')) || [];
    console.log(recentlyUsedTokens)
    setRecentlyUsedTokens(storedRecentlyUsedTokens);
  }, []);


  useEffect(() => {

    const fetchTokenInfo = async () => {
      if (searchTerm.length < 40){
        setLocalError('')
        setVerifiedTokenInfo(null);
      }
      try {
        if (searchTerm.length >= 40) {
          const info = await getTokenInfo(searchTerm);
          setVerifiedTokenInfo(info);
          setLocalError('');
          if (info) {
            // Include additional details in the recently used tokens
            const updatedRecentlyUsedTokens = [
              { name: info.name, symbol: info.symbol, address: searchTerm, decimals: info.decimals },
              ...recentlyUsedTokens.filter((t) => t.address !== searchTerm),
            ].slice(0, 3); // Limit to the three most recent tokens
            
            console.log(updatedRecentlyUsedTokens)
            setRecentlyUsedTokens(updatedRecentlyUsedTokens);
      
            // Save recently used tokens to local storage
            localStorage.setItem('recentTokens', JSON.stringify(updatedRecentlyUsedTokens));
          }
        }
      } catch (error) {
        setLocalError('Invalid contract address');
        setVerifiedTokenInfo(null);
      }
    };

    if (searchTerm.length > 0 || isOpen) {
      fetchTokenInfo();
    }

  }, [searchTerm]);

  const handleTokenChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);

    const newMatchingTokens = tokenList.filter((token) =>
      token.name.toLowerCase().includes(newSearchTerm.toLowerCase())
    );
    setMatchingTokens(newMatchingTokens);
  };

  const handleTokenSelect = async (token) => {
    if (token.address !== selectedTokenInOtherField) {
      setTokenContractAddress(token.address);
      setTokenName(token.name);
      setVerifiedTokenInfo({name: token.name, symbol: token.symbol || token.name || '', decimals: token.decimals || 18, image: tokenList.find(t => t.address === token.address).img || null})
      clearTokenValues()
      closeModal();
    } else if (selectedTokenInOtherField) {
      // Swap tokens if the same token is selected and both tokens are selected
      if (currentSelectedToken){
        handleSwapTokens();
        closeModal();
      }
      else{
        closeModal()
      }

    }
  };

  const handleSearchTokenSelect = (token) => {
    if (token !== selectedTokenInOtherField) {
      setTokenContractAddress(token);
      setTokenName(token);
      closeModal();
    } else if (selectedTokenInOtherField) {
      // Swap tokens if the same token is selected and both tokens are selected
      if (currentSelectedToken){
        handleSwapTokens();
        closeModal();
      }
      else{
        closeModal()
      }

    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (matchingTokens.length > 0) {
        // Select the first matching token
        const selectedToken = matchingTokens[0];

        handleTokenSelect(selectedToken);
      } else {
        // Use the exact input if there's no match
        handleSearchTokenSelect(searchTerm);
      }
    }
  };

  return (
    <div
      style={{
        display: isOpen ? 'flex' : 'none',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
      }}
      className='token-modal'
    >
      <div className='modal-header'>
      <p>Select Token</p>
      <button onClick={closeModal}>X</button>
      </div>
     
        <input
          type="text"
          value={searchTerm}
          onChange={handleTokenChange}
          onKeyDown={handleKeyDown}
          placeholder='Search for a token or contract address'
        />

        {localError && <p style={{ color: 'red' }}>{localError}</p>}

      {verifiedTokenInfo && 
      <div>
        <p>{verifiedTokenInfo.name}, ${verifiedTokenInfo.symbol}, {verifiedTokenInfo.decimals}</p>
      </div>
      }

        <ul className='recent-token-select'>
          {recentlyUsedTokens.map((recentToken) => (
            <li key={recentToken.address} className='recent-token' onClick={() => handleTokenSelect(recentToken)}>
            <div className='token-icon'><img src={tokenList.find(t => t.address === recentToken.address) ? tokenList.find(t => t.address === recentToken.address).img : questionToken} alt="image" /> </div> {recentToken.symbol}
            </li>
          ))}
        </ul>

      <ul>
        {matchingTokens.map((token) => (
          <li key={token.address} onClick={() => handleTokenSelect(token)}>
          <div className='token-icon'><img src={tokenList.find(t => t.address === token.address) ? tokenList.find(t => t.address === token.address).img : questionToken} alt="image" /></div> {token.name}
        </li>
        ))}
      </ul>

      
    </div>
  );
};

export default ChooseTokenModal;
