import React, { useState } from 'react';
import '../styles/modalStyles.css';

const SettingsModal = ({ slippage, setSlippage, closeModal, isOpen }) => {
  const handleSliderChange = (e) => {
    const newValue = parseFloat(e.target.value);
    setSlippage(newValue);
  };

  const handleInputBlur = () => {
    // Ensure slippage is within the valid range (0 to 1)
    if (slippage < 0) {
      setSlippage(0);
    } else if (slippage > 1) {
      setSlippage(1);
    }
  };

  return (
    <div
      style={{
        display: isOpen ? 'flex' : 'none',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
      }}
      className='token-modal'
    >
      <div className='modal-header'>
        <p>Settings</p>
        <button onClick={() => {closeModal()}}>X</button>
      </div>

      <div className='modal-content'>
        <label>Slippage:</label>
        <input
          type="number"
          name='slippage'
          value={slippage}
          onChange={handleSliderChange}
          onBlur={handleInputBlur}
          step="0.01" // Allows increments of 0.01
          min="0"
          max="1"
          placeholder='Set Slippage'
        />
        <input
          type="range"
          name='slippage'
          value={slippage}
          onChange={handleSliderChange}
          step="0.01"
          min="0"
          max="1"
        />
      </div>
    </div>
  );
};

export default SettingsModal;
