import { ethers } from "ethers";
import { tokenABI } from "../ABIs/TokenABI";

export default async function getTokenInfo(tokenAddress, signer=null) {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signerValue = signer? signer : provider.getSigner();
    const tokenContract = new ethers.Contract(tokenAddress, tokenABI, signerValue);

    const [name, symbol, decimals] = await Promise.all([
      tokenContract.name(),
      tokenContract.symbol(),
      tokenContract.decimals(),
    ]);

    return { name, symbol, decimals };
  } catch (error) {
    console.error("Error fetching token info:", error);
    throw new Error("Error fetching token info");
  }
}

