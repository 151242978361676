import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import gopherSwapTextLogo from '../images/GopherSwapLogo.png'
import '../styles/home.css'

function LandingHeader(props) {

  return (
    <header className='landing-header'>

<div className='header-main-container'>
<Link to="/" className='header-title'><img src={gopherSwapTextLogo} style={{height: '30px'}}></img></Link>
        <ul className='nav-items'>
            <a href='https://medium.com/introduction-to-gopherswap-quantum-resistant-dex-a' target='_blank'><li className='menu-item'> How to use? </li></a>
            <a href='https://medium.com/@n.y21/deploying-smart-contracts-to-the-qan-private-chain-aa41c448ca64' target='_blank'><li className='menu-item'> Deploy Tokens </li></a>
            <a href='https://medium.com/@qanx.dev.community' target='_blank'><li className='menu-item'> Developer Community </li></a>
        
        </ul>      
</div>

<div className='status-container'>
    <p>QAN Private Testnet is down for maintenence. Click <a style={{cursor: 'pointer', color: 'lightblue'}} href='https://twitter.com/gopherswap' target='_blank'>Here</a> to learn more.</p>
</div>       
        
    </header>
  )
}

export default LandingHeader