import React from 'react';
import MakePostLogo from '../images/GopherSwapLogo.png';
import TD from '../images/GopherSwapLogo.png';
import '../styles/footer.css'

function Footer() {
  return (
    <div className="footerContainer">
      <div>
        <a href="/"><img src={MakePostLogo} width="150px" alt="MakePost Logo" /></a>
      </div>
      <div className="footerColumn">
        <h1 className="footerHeader">Developers</h1>
        <li><a href="https://medium.com/@n.y21/deploying-smart-contracts-to-the-qan-private-chain-aa41c448ca64">Quantum-Resistant Tokens</a></li>
        <li><a href="https://learn.qanplatform.com/developers/qvm-multi-language-smart-contracts/docs-for-supported-languages">Multi-Language Smart Contracts</a></li>
        <li><a href="https://learn.qanplatform.com/developers/qan-testnet#the-qan-xlink-protocol">Learn about XLink Protocol</a></li>
      </div>
      <div className="footerColumn">
        <h1 className="footerHeader">Company</h1>
        <li><a href="/">About</a></li>
        <li><a href="https://twitter.com/gopherswap">Twitter</a></li>
      </div>
      <div className="footerColumn">
        <h1 className="footerHeader">Resources</h1>
        <li><a href="">Privacy Policy</a></li>
        <li><a href="">Terms of Use</a></li>
        <li><a href="">Cookie Policy</a></li>
        <li><a href="">Help</a></li>
        <li><a href="">Feedback</a></li>
      </div>
      <div className="footerColumn">
        <h1 className="footerHeader">More</h1>
        <li><a href="">Web3</a></li>
      </div>
    </div>
  );
}

export default Footer;
