import React, { useState, useEffect, useMemo } from 'react'
import {BigNumber, ethers, utils} from 'ethers';
import SwapModal from './components/SwapModal'
import { useCommonProps } from './contexts/commonPropContext';
import './App.css';



const Body = (props) => {

    const {
        error,
        setError,
        chainDetails
      } = useCommonProps();


/*
    async function callContract() {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const numberContract = new ethers.Contract(contractAddress, NFTcontract, provider)
            const num = await numberContract.totalSupply()
            setMinted(parseInt(num._hex))
        }
        catch(e) {
            setLocalError("Loading Contract Failed.")
            setError("Contract Failed to Load: Please Switch to QANX Private TestNet to Load Contracts")
            console.log(e)
            console.log(contractAddress)
        }
    }

    async function switchNetwork() {
        try {
            setLoading(true)
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: utils.hexValue(12346)}],
            });
            setLoading(false)
          } catch {
            // This error code indicates that the chain has not been added to MetaMask.
            console.log("Catch Happening")
            setLoading(true)
                await window.ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [{
                        chainId: utils.hexValue(12346),
                        chainName: "QANX Private TestNet",
                        nativeCurrency: { name: "tQANX", symbol: "tQANX", decimals: 18 },
                        rpcUrls: ["http://rpc.qanx.live:8545/"],
                        blockExplorerUrls: ["https://qanx.live/"]
                    }],
                });
                setLoading(false)

            // handle other "switch" errors
          }
    }

    */


  
    return (
    <div>
     <div className='orc-body-2' ></div>
     <div className='orc-body-3'></div>

        {error? 
        <div className='error-div'><div>{error.message.length > 50? <p>Oops that transaction Failed, View transaction error message -</p> : <p>{error.message}</p> }</div> <div> {error.tx?<a href={`${chainDetails.blockExplorerUrls[0]}tx/${error.tx}`} target='_blank' >View Transaction</a> : null}</div></div>
        :
        null  
        }

        <SwapModal />

     </div>
    )
}

export default Body