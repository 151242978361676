import React, {useEffect, useState} from 'react'
import { ethers } from 'ethers'
import { ABI } from './ABI'
import {Link} from 'react-router-dom';
import qanLogo from './images/qanLogo.png'
import monIcon from './images/mon-icon.png'
import gopherSwapTextLogo from './images/GopherSwapLogo.png'
import { useCommonProps } from './contexts/commonPropContext';
import walletadd from './images/WalletAdd.svg';

function Header(props) {


const { isConnected, setConnected, accounts, setAccounts, error, setError, loading, setLoading, connectWalletHandler, balance, disconnectWalletHandler, noEth } = useCommonProps();

const contractAddress = '0xb62C298B0173E7A0b5EEA9FCAa1f72227AF86bd9'

useEffect(() => {
    {
        if (window.ethereum) {
        // The "any" network will allow spontaneous network changes
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        provider.on("network", (newNetwork, oldNetwork) => {
            // When a Provider makes its initial connection, it emits a "network"
            // event with a null oldNetwork along with the newNetwork. So, if the
            // oldNetwork exists, it represents a changing network
            if (oldNetwork) {
                window.location.reload();
            }
        });
        }

    }


}, [])
  


  return (
    <div className='Header'>
        <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
<defs>
<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
</defs>
<g className="parallax">
<use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(0, 0, 0,0.7" />
<use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(0, 0, 0,0.5)" />
<use xlinkHref="#gentle-wave" x="48" y="5" fill="rgb(0, 0, 0)" />
<use xlinkHref="#gentle-wave" x="48" y="7" fill="rgb(0, 0, 0)" />
</g>
</svg>
<div className='header-main-container'>
<Link to="/" className='header-title'><img src={gopherSwapTextLogo} style={{height: '30px'}}></img></Link>
        <ul className='nav-items'>
            <a href='https://medium.com/introduction-to-gopherswap-quantum-resistant-dex-a' target='_blank'><li className='menu-item'> How to use? </li></a>
            <a href='https://medium.com/@n.y21/deploying-smart-contracts-to-the-qan-private-chain-aa41c448ca64' target='_blank'><li className='menu-item'> Deploy Tokens </li></a>
            <a href='https://medium.com/@qanx.dev.community' target='_blank'><li className='menu-item'> Developer Community </li></a>
        
        </ul>      
</div>

<div className='status-container'>
    <p>QAN Private Testnet is down for maintenence. Click <a style={{cursor: 'pointer', color: 'lightblue'}} href='https://twitter.com/gopherswap' target='_blank'>Here</a> to learn more.</p>
</div>


       { !noEth && (
            accounts? 
            <div>
            <div style={{color: "white"}} onClick={() => {disconnectWalletHandler()}}><h3 className='sign-in-connected'>{accounts.slice(0,4)}...{accounts.slice(35)}</h3></div>         
            </div>
            :
            <div className='sign-in' onClick={connectWalletHandler}><img src={walletadd} width='25px'></img></div>
       )
       }

         
        
    </div>
  )
}

export default Header