import React from 'react';
import { Canvas } from 'react-three-fiber';
import { OrbitControls } from '@react-three/drei';

const FloatingSphere = ({ color, position, top, left, zindex, id='' }) => {
  const rand = Math.random() * (8 - 3) + 3; 
  return (
    <div className={id? 'floating-ball no-mobile' : 'floating-ball'} style={{ position: 'absolute', width: '300px', height: '300px', top: top, left: left, animation: `floatAnimation ${rand}s ease-in-out infinite`, zIndex: zindex }}>
      <Canvas camera={{ position: [0, 0, 15] }}>
        <OrbitControls enableZoom={false} enableRotate={false} />
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <mesh position={[position.x, position.y, position.z]}>
          <sphereGeometry args={[1, 32, 32]} visible castShadow />
          {/* Adjust metalness and roughness for glossy or glass appearance */}
          <meshStandardMaterial color={color} metalness={0.9} roughness={0.4} />
        </mesh>
      </Canvas>
    </div>
  );
};

export default FloatingSphere;
