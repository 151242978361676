import React from 'react';
import FloatingSphere from '../background/Ball';
import '../styles/home.css'
import CheckComponent from '../components/checkComponent';



function WhyQan() {
  return (
    <div className="why-qan-container" style={{ position: 'relative'}}>
        <h2>Why QAN?</h2>
        <div className="why-qan-div">
        <div className='why-qan-card'>
            <h2>Game Changing Layer 1</h2>
        </div>
        <div className='why-qan-card'>
            <ul className='why-qan-list'>
                <li><CheckComponent />Quantum Resistant</li>
                <li><CheckComponent />Highly Decentralized</li>
                <li><CheckComponent />Fast Transaction Speeds</li>
                <li><CheckComponent />Hybrid Blockchain</li>
                <li><CheckComponent />EVM Compatible</li>
                <li><CheckComponent />Code in Any Language</li>

            </ul>
        </div>
        <div className='why-qan-card'>
            <h3>Learn More About QANPlatform</h3>
            <div>
            <a href='https://www.qanplatform.com/en' target='_blank'><button className='btn-primary'>Learn More</button></a>
            </div>
        </div>
      {/* First sphere */}
      <div> 
        <FloatingSphere color="blue" position={{ x: 0, y: 0, z: 10 }} top={"0%"} left={"30vw"} zindex={900} />
      </div>

      {/* Second sphere */}
      <div>
        <FloatingSphere color="blue" position={{ x: 0, y: 0, z: 2 }} top={"30%"} left={"80vw"} zindex={900} />
      </div>

      {/* Third sphere */}
      <div>
        <FloatingSphere color="#FFFFFF" position={{ x: 0, y: 0, z: 7 }} top={"40%"} left={"0"} zindex={900} id={"nomobile-1"} />
      </div>

      {/* Fourth sphere */}
            <div>
        <FloatingSphere color="#FFFFFF" position={{ x: 0, y: 0, z: 11 }} top={"10%"} left={"87vw"} zindex={15} />
      </div>

            {/* Fifth sphere */}
            <div>
        <FloatingSphere color="blue" position={{ x: 0, y: 0, z: 11 }} top={"10%"} left={"-10vw"} zindex={15} />
      </div>

        </div>
    </div>
  );
}

export default WhyQan;
