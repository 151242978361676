// CommonPropsContext.js
import { createContext, useContext } from 'react';

const CommonPropsContext = createContext();

export const CommonPropsProvider = ({ children, commonProps }) => {
  return (
    <CommonPropsContext.Provider value={commonProps}>
      {children}
    </CommonPropsContext.Provider>
  );
};

export const useCommonProps = () => {
  return useContext(CommonPropsContext);
};
