import React from "react";
import "../styles/home.css";
import LandingHeader from "./LandingHeader";
import WhyQan from "./whyQan";
import { Link } from "react-router-dom";
import QuantumParticlesBackground from "../background/QuantumArt";
import Footer from "../components/Footer";
import Gopher from "../images/GopherSwapLogo.png"

function Home() {
  return (
    <div className="container">
        <LandingHeader />
        <div className="landing-body">

      <div className="main-section">
        <h1>GopherSwap</h1>
        <div style={{maxWidth: '680px'}}>
        <p>Quantum-Resistant Trading Protocol, A Paradigm Shift in Decentralized Finance built on QANPlatform</p>
        </div>
        <p>Now available in Beta on QAN Private Testnet</p>
        <a href="/swap" target="_blank"><button className="btn-primary">Go Swap</button></a>
        <div className="quantum-art">
        <QuantumParticlesBackground />
        </div>

      </div>

      <WhyQan />

      <section className="dev-community-banner">
        <div>
        <h1 className="large-title">Developers <br></br>Community</h1>
       <a href="https://medium.com/@qanx.dev.community" target="_blank"><button className="btn-primary">Learn More </button></a> 
        </div>
        <div className="education-container">
          <a className="edu-link" href="https://medium.com/@n.y21/deploying-smart-contracts-to-the-qan-private-chain-aa41c448ca64" target="_blank">
          <div className="education-card">
            <h4>Learn How To Deploy Quantum-Resistant Tokens</h4>
          </div>
          </a>
          <a className="edu-link" href="https://learn.qanplatform.com/developers/qvm-multi-language-smart-contracts/docs-for-supported-languages" target="_blank">
          <div className="education-card">
            <h4>Develop Multi-Language Smart Contracts</h4>
          </div>
          </a>
          <a className="edu-link" href="https://learn.qanplatform.com/developers/qan-testnet#the-qan-xlink-protocol" target="_blank">
          <div className="education-card">
            <h4>Learn about XLink Protocol</h4>
          </div>
          </a>
          <a className="edu-link" href="https://twitter.com/gopherswap" target="_blank">
          <div className="education-card">
            <h4>Join the Community Journey</h4>
          </div>
          </a>
        </div>
      </section>

      <section className="gopher-banner">
        <img src={Gopher} />
        <div>
          <h1>"Gophers love to build tunnels, they connect two different points together in space, hopefully we can carry that into web3, connecting the world with the power of decentralized computing"</h1>
          <h5>GopherSwap - a subtle reference to quantum tunneling and GoLang.</h5>
        </div>
      </section>
    </div>
    <Footer />
    </div>
  );
}

export default Home;
