import "./App.css";
import Body from "./Body";
import Header from "./Header";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ethers } from "ethers";
import Art from "./background/Art";
import MetaIcon from "./images/MetaMask.png";
import MetaImage from "./images/MetamaskDoc.PNG";
import web3 from "web3";
import { CommonPropsProvider } from "./contexts/commonPropContext";
import { tokenABI } from "./ABIs/TokenABI";
import getTokenInfo from "./utils/functions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Swap() {
  const [isConnected, setConnected] = useState(false);
  const [supply, setSupply] = useState(0);
  const [accounts, setAccounts] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCompatible, setCompatible] = useState(true);
  const [contractABI, setContractABI] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [chainID, setChainID] = useState(12346);
  const [wrappedToken, setWrappedToken] = useState("");
  const [chainDetails, setChainDetails] = useState({});
  const [noEth, setNoEth] = useState(false)

  const [contractNum, setContractNum] = useState(0);
  const [contractName, setContractName] = useState("");

  const [balance, setBalance] = useState(0);

  useEffect(() => {
    // Check if the user was previously connected by looking into localStorage
    const storedConnectedStatus = localStorage.getItem('isConnected');
    
    if (storedConnectedStatus === 'true' && window.ethereum) {
      setConnected(true); // Update the state to reflect that the user was previously connected
      window.ethereum.request({ method: 'eth_accounts' })
        .then(accounts => {
          if (accounts.length > 0) {
            setAccounts(accounts[0]); // Set the account if available
            fetchBalance()
          }
        })
        .catch(err => {
          console.error(err);
          // Handle error, possibly update UI to show that the wallet connection failed
        });
    }

    if (window.ethereum) {
      pickContract();
      handleChainChanged();
      window.ethereum.on("accountsChanged", handleAccountsChanged);
      window.ethereum.on("chainChanged", handleChainChanged);
    }

    if (!window.ethereum) {
      setNoEth(true)
    }

    // Cleanup function
    return () => {
      if (window.ethereum) {
      // Remove event listeners or perform any necessary cleanup
      window.ethereum.off("accountsChanged", handleAccountsChanged);
      window.ethereum.off("chainChanged", handleChainChanged);
      }
    };
  }, []);

  useEffect(() => {
    // Store the connection status in localStorage whenever it changes
    localStorage.setItem('isConnected', isConnected);
  }, [isConnected]);

  useEffect(() => {
    if (error){
      toast.error(error)
    }
  }, [error])

  const handleAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
      // User has disconnected their wallet
      setConnected(false);
      localStorage.setItem('isConnected', false);
    } else {
      // User has connected their wallet
      setAccounts(accounts[0]);
      setConnected(true);
      localStorage.setItem('isConnected', true);
      // Fetch other necessary data here
    }
  };

  const handleChainChanged = async () => {
    const expectedNetworkId = parseInt(chainID); // Replace with your expected network ID
    const currentNetworkId = await window.ethereum.request({
      method: "eth_chainId",
    });

    if (parseInt(currentNetworkId) !== expectedNetworkId) {
      // Network mismatch, disable or hide parts of the UI
      setCompatible(false);
      console.log(
        `Please switch to the correct network. Expected network ID: ${expectedNetworkId}`
      );
      // You may also disable buttons, hide components, etc.
    } else {
      // Reset error and enable UI
      setError("");
      setCompatible(true);
      // You can enable UI components here if needed
    }
  };

  async function getQuote(amount, address1, address2) {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const numberContract = new ethers.Contract(
        contractAddress,
        contractABI,
        provider
      );

      // Convert amount to Wei using ethers
      const weiAmount = ethers.utils.parseEther(amount.toString());

      //console.log("Wei Amount", weiAmount);

      // Convert Wei back to Ether for display (optional)
      const etherAmount = ethers.utils.formatEther(weiAmount);
      //console.log("Ether Amount", etherAmount);

      //Check to see if the Token is the Native Coin
      if (
        address1.toLowerCase() ===
        "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
      ) {
        address1 = wrappedToken;
      }
      if (
        address2.toLowerCase() ===
        "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
      ) {
        address2 = wrappedToken;
      }

      //console.log(address1, address2)

      // Initialize the path array
      let path = [address1, address2];

      // If both addresses are not WETH, include WETH in the path
      if (address1 !== wrappedToken && address2 !== wrappedToken) {
        path.splice(1, 0, wrappedToken);
      }

      const num = await numberContract.getAmountsOut(
        ethers.utils.parseEther(etherAmount.toString()),
        path
      );
      setSupply(parseInt(num[0]._hex));
      //console.log(parseInt(num[0]._hex));
      //console.log(parseInt(num[1]._hex));

      //console.log(num);

      return parseFloat(
        ethers.utils.formatEther(web3.utils.hexToNumber(num[1]._hex))
      )
        .toFixed(5)
        .toString();
      // Takes the wei amount out, converts it to eth amount then floors the value to drop decimal
    } catch (e) {
      console.error(e);
    }
  }

  async function ApproveToken(tokenIn, tokenInValue, signer, tokenDecimals) {
    const tokenContract = new ethers.Contract(tokenIn, tokenABI, signer);
    const amountToApprove = ethers.utils.parseUnits(
      tokenInValue,
      tokenDecimals
    );
    const uniswapRouterAddress = contractAddress;

    // Check current allowance
    const currentAllowance = await tokenContract.allowance(
      await signer.getAddress(),
      uniswapRouterAddress
    );

    if (currentAllowance.lt(amountToApprove)) {
      // Current allowance is less than the amount we want to approve, so we need to approve
      const approveTx = await tokenContract.approve(
        uniswapRouterAddress,
        amountToApprove
      );
      const approveTxResult = await approveTx.wait();

      if (approveTxResult.status === 1) {
        console.log(`Approval successful: ${approveTxResult.transactionHash}`);
      } else {
        throw new Error("Approval failed");
      }
    } else {
      // Current allowance is already sufficient
      console.log("Approval not needed, sufficient allowance already granted.");
    }
  }

  async function swapTokens(
    tokenIn,
    tokenInValue,
    tokenOut,
    tokenOutMinValue,
    tokenDecimals = 18,
    slippage = 0.65
  ) {
    /*
  console.log("TokenIn:", tokenIn)
  console.log("TokenInValue:", tokenInValue)
  console.log("tokenOut:", tokenOut)
  console.log("tokenOutMinValue:", tokenOutMinValue)
  console.log("tokenDecimals:", tokenDecimals)
  */

  
    //get a final quote for swapping
    try {
      tokenOutMinValue = await getQuote(tokenInValue, tokenIn, tokenOut)
    }
    catch {
      setError("Cannot get a Final Quote Price")
      throw new Error("Cannot get a Final Quote Price");
    }

    

    const tokenOutMinValueWithSlippage = tokenOutMinValue * (1 - slippage); // Adjust minimum value based on slippage

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const accounts = await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();

    const routerContract = new ethers.Contract(
      contractAddress,
      contractABI,
      signer
    );

    var _token1Decimals = tokenDecimals
    var _token2Decimals = tokenDecimals

    var token1VerifiedInfo
    var token2VerifiedInfo

      //checks for valid decimals and verified token info
    if (
      !tokenIn.toLowerCase() ===
      "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
    ) {
      try {
        token1VerifiedInfo = getTokenInfo(tokenIn)

        if (token1VerifiedInfo.decimals) {
          _token2Decimals = token2VerifiedInfo.decimals
        }
        else {
          _token1Decimals = 18
        }
      }
      catch {
        setError("Invalid Input Token")
        throw new Error("Invalid Input Token");

      }
    }
    
    if (
      !tokenOut.toLowerCase() ===
      "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
    ) {
      try {
        token2VerifiedInfo = await getTokenInfo(tokenOut)

        if (token1VerifiedInfo.decimals) {
          _token2Decimals = token2VerifiedInfo.decimals
        }
        else {
          _token2Decimals = 18
        }
      }
      catch {
        setError("Invalid Output Token")
        throw new Error("Invalid Output Token");
      }
    }
    

    // Convert token values to string and ensure they are in the correct format
    const tokenInValueStr = tokenInValue.toString();
    const tokenOutMinValueStr = tokenOutMinValueWithSlippage.toString();

    // Use the correct decimals for the input and output values
    const amountIn = ethers.utils.parseUnits(tokenInValueStr, _token1Decimals);
    console.log("Amount In: ", amountIn);
    const amountOutMin = ethers.utils.parseUnits(
      tokenOutMinValueStr,
      _token2Decimals
    );

    const deadline = Math.floor(Date.now() / 1000) + 60 * 10; // 10 minutes from now

    // Estimate gas limit or use a function to calculate it dynamically
    /*
var estimatedGasLimit
try {
 estimatedGasLimit = await routerContract.estimateGas.swapExactTokensForTokens(
    amountIn,
    amountOutMin,
    [tokenIn, tokenOut],
    accounts[0],
    deadline,
    {
      gasLimit: 21000000000,
      gasPrice: 8000000000,
    }
  );
}
catch(e) {
  console.log(e)
  setError('Insufficient ETH balance to cover gas fees.')
  throw new Error('Insufficient ETH balance to cover gas fees.');
}
*/

    const gasPrice = await provider.getGasPrice();
    const gasLimit = gasPrice.add(10000); // Adding a buffer of 20,000

    //console.log(gasPrice)
    //console.log(gasLimit)

    // Check the user's ETH balance for gas
    const userEthBalance = await provider.getBalance(accounts[0]);
    //console.log(parseFloat(ethers.utils.formatEther(web3.utils.hexToNumber(userEthBalance._hex))).toFixed(5).toString())
    const usersWeiBalance = ethers.utils.parseUnits(
      parseFloat(
        ethers.utils.formatEther(web3.utils.hexToNumber(userEthBalance._hex))
      )
        .toFixed(5)
        .toString(),
        18
    );
    const estimatedGasCost = gasLimit;

    //console.log(estimatedGasCost)
    //console.log(usersWeiBalance)

    if (usersWeiBalance.lt(estimatedGasCost)) {
      setError("Insufficient ETH balance to cover gas fees.");
      throw new Error("Insufficient ETH balance to cover gas fees.");
    }
    if (
      tokenIn.toLowerCase() !== "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
    ) {
      // Check the user's token balance
      const tokenContract = new ethers.Contract(tokenIn, tokenABI, signer);
      const userTokenBalance = await tokenContract.balanceOf(accounts[0]);
      const requiredTokenAmount = amountIn;

      if (userTokenBalance.lt(requiredTokenAmount)) {
        setError("Insufficient token balance for the swap.");
        throw new Error("Insufficient token balance for the swap.");
      }

      await ApproveToken(tokenIn, tokenInValue, signer, _token1Decimals); // Await the approval before attempting the swap
    }

    var swapTx;

    if (
      tokenIn.toLowerCase() ===
      "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
    ) {
      // If tokenIn is Ether, use swapExactETHForTokens
      swapTx = await routerContract.swapExactETHForTokens(
        amountOutMin,
        [wrappedToken, tokenOut], //first address in path must be wrapped token
        accounts[0],
        deadline,
        { gasLimit: 165757, value: amountIn } // Specify the value parameter for the amount of Ether being swapped
      );
    } else if (
      tokenOut.toLowerCase() ===
      "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
    ) {
      // If tokenOut is Ether, use swapExactTokensForETH
      swapTx = await routerContract.swapExactTokensForETH(
        amountIn,
        amountOutMin,
        [tokenIn, wrappedToken], // Last address in path must be wrapped token
        accounts[0],
        deadline,
        { gasLimit: 165757 }
      );
    } else {
      // If tokenIn is an ERC-20 token, use swapExactTokensForTokens
      swapTx = await routerContract.swapExactTokensForTokens(
        amountIn,
        amountOutMin,
        [tokenIn, wrappedToken, tokenOut],
        accounts[0],
        deadline,
        { gasLimit: 165757 }
      );
    }

    const receipt = await swapTx.wait();

    // Access the Swap event from the receipt
    const swapEvent = receipt.events.find((event) => event.event === "Swap");

    // Log the details of the Swap event
    if (swapEvent) {
      console.log("Swap Event Details:", swapEvent.args);
    } else {
      console.warn("Swap Event not found in the transaction receipt.");
    }

    console.log("Token swap successful!");
    toast.success("Token Swap Succesful!!", { autoClose: 15000, autoClose: false })
    return { routerContract, swapEvent };
  }

  async function pickContract() {
    switch (contractNum) {
      case 0:
        await import("./ABIs/SwapABI7").then((contract) => {
          setContractABI(contract.TestABI);
          setContractAddress(contract.contractAddress);
          setContractName(contract.contractName);
          setChainID(contract.connectedChain);
          setWrappedToken(contract.WrappedToken);
          setChainDetails(contract.chainDetails);
        });
        break;
      default:
        await import("./ABIs/SwapABI7").then((contract) => {
          setContractABI(contract.TestABI);
          setContractAddress(contract.contractAddress);
          setContractName(contract.contractName);
          setChainID(contract.connectedChain);
          setWrappedToken(contract.WrappedToken);
          setChainDetails(contract.chainDetails);
        });
        break;
    }
  }

  const connectWalletHandler = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          accountChangedHandler(result[0]);
          fetchBalance();
          setConnected(true); // Update connection status
        });
    } else {
      setError("Please Install MetaMask");
    }
  };


  const disconnectWalletHandler = () => {
    // Add logic to disconnect the wallet
    setConnected(false);
    setAccounts("")
    setBalance(0)
    // Additional cleanup logic if needed
  };

  const accountChangedHandler = (newAccount) => {
    //console.log(newAccount);
    setAccounts(newAccount);
  };

  const fetchBalance = async () => {
    try {
      const _accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      //console.log("Accounts:", _accounts);
      setAccounts(_accounts[0]);

      if (_accounts.length > 0) {
        const provider = window.ethereum;
        const balanceInWei = await provider.request({
          method: "eth_getBalance",
          params: [_accounts[0], "latest"],
        });

        // Replace this line with the appropriate conversion based on your native token's decimal places
        const balanceInNativeToken = parseFloat(
          ethers.utils.formatUnits(balanceInWei, 18)
        );

        //console.log("Balance in Wei:", balanceInWei);
        //console.log("Balance in Native Token:", balanceInNativeToken);

        setBalance(balanceInNativeToken);
      }
    } catch (e) {
      setError("Error fetching balance");
      console.error(e);
    }
  };

  const commonProps = {
    isConnected,
    setConnected,
    accounts,
    setAccounts,
    error,
    setError,
    loading,
    setLoading,
    connectWalletHandler,
    getQuote,
    balance,
    fetchBalance,
    isCompatible,
    chainDetails,
    swapTokens,
    disconnectWalletHandler,
    noEth
  };

  return (
    <div id="swap-div">
      <Art />
      <ToastContainer />
          <CommonPropsProvider commonProps={commonProps}>
            <Header />
            <Body />
          </CommonPropsProvider>
    </div>
  );
}

export default Swap;
