import React, { useState, useRef, useEffect } from "react";
import qanToken from "../images/qanBlackLogo.png";
import questionToken from "../images/question-icon.png"
import ChooseTokenModal from "./ChooseTokenModal";
import { useCommonProps } from "../contexts/commonPropContext";
import { utils } from "ethers";
import SettingsModal from "./SettingsModal";
import {Grid, Blocks} from 'react-loader-spinner'

const SwapModal = (props) => {
  const [modal1IsOpen, setModal1IsOpen] = useState(false);
  const [tokenContractAddress1, setTokenContractAddress1] = useState("0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE");
  const [token1Value, setToken1Value] = useState(null);
  const [tokenName1, setTokenName1] = useState("tQANX");
  const [verifiedTokenInfo1, setVerifiedTokenInfo1] = useState({ name: 'tQANX', symbol: 'tQANX', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18, image: qanToken });


  const [modal2IsOpen, setModal2IsOpen] = useState(false);
  const [tokenContractAddress2, setTokenContractAddress2] = useState("");
  const [token2Value, setToken2Value] = useState(null);
  const [tokenName2, setTokenName2] = useState("");
  const [verifiedTokenInfo2, setVerifiedTokenInfo2] = useState(null);


  const [slippage, setSlippage] = useState(0.05);
  const [showSettings, setShowSettings] = useState(false)

  const [localLoading, setLocalLoading] = useState(false)

  const timeoutRef = useRef(null);



  const {
    isConnected,
    setConnected,
    accounts,
    setAccounts,
    error,
    setError,
    loading,
    setLoading,
    connectWalletHandler,
    balance,
    fetchBalance,
    getQuote,
    callContract,
    isCompatible,
    chainDetails,
    swapTokens,
    noEth
  } = useCommonProps();

  const openModal1 = () => {
    setModal1IsOpen(true);
  };

  const closeModal1 = () => {
    setModal1IsOpen(false);
  };

  const openModal2 = () => {
    setModal2IsOpen(true);
  };

  const closeModal2 = () => {
    setModal2IsOpen(false);
  };

  const closeSettings = () => {
    setShowSettings(false);
  };

  const handleSwapTokens = () => {
    // Swap token contracts and names not the swap function
    setTokenContractAddress1(tokenContractAddress2);
    setTokenName1(tokenName2);
    setToken1Value(token2Value);
    setVerifiedTokenInfo1(verifiedTokenInfo2)
    setTokenContractAddress2(tokenContractAddress1);
    setTokenName2(tokenName1);
    setToken2Value(token1Value);
    setVerifiedTokenInfo2(verifiedTokenInfo1)

  };

  const handleSwap = async () => {
    setLoading(true);
    setError('')
  
    let routerContract, swapEvent;
  
    try {
      // Call the swapTokens function from commonProps
      const result = await swapTokens(
        tokenContractAddress1,
        token1Value,
        tokenContractAddress2,
        token2Value,
        18,
        slippage
      );
  
      // Destructure the result to get routerContract and swapEvent
      ({ routerContract, swapEvent } = result);
    } catch (e) {
      console.log(e);

      const errorMessage = e.message;
      const transactionHash = e.transactionHash || '';

      console.log(errorMessage)
      console.log(transactionHash)
      
      if (!error) {
        setError({message: errorMessage, tx: transactionHash})
      }

      clearTokenValues()
      fetchBalance()
      setLoading(false);
      return;
    }
  
    // Use routerContract and swapEvent as needed
    if (routerContract && swapEvent) {
      // Subscribe to Swap event after the tokens are swapped
      const filter = routerContract.filters.Swap();
      const eventListener = routerContract.on(filter, (...args) => {
        console.log({ args });
      });
  
      return () => {
        eventListener.removeListener();
        setLoading(false);
      };
    } else {
      // Handle the case where routerContract or swapEvent is undefined
      console.error('Error: routerContract or swapEvent is undefined');
      clearTokenValues()
      fetchBalance()
      setLoading(false);
    }
  };
  

  const clearTokenValues = () => {
    setToken1Value('');
    setToken2Value('');
  };

  const handleGetQuote = async (value) => {
    setLocalLoading(true)
    setToken1Value(value);
    setToken2Value(0);
    if (value > 0) {
      let quote = await getQuote(
        value,
        tokenContractAddress1,
        tokenContractAddress2
      );
      console.log(quote);
      setToken2Value(quote);
    } else {
      setToken2Value(0);
    }
    setLocalLoading(false)
  };

  const delayedHandleInputChange = (e) => {
    clearTimeout(timeoutRef.current);
    const sanitizedValue = e.target.value.replace(/[^0-9.]/g, ""); // Allow only digits and a single dot
    setToken1Value(sanitizedValue);
    if (tokenContractAddress1 && tokenContractAddress2) {
      setLocalLoading(true)
    }
    timeoutRef.current = setTimeout(() => {
      // Perform the actions you want after the user has stopped typing for 1 second
      tokenContractAddress1 && tokenContractAddress2 && handleGetQuote(sanitizedValue);
    }, 1000); // Adjust the duration (in milliseconds) as needed
  };
  
  // Clear the timeout on component unmount
  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  async function switchNetwork() {
    try {
      setLoading(true);
      console.log("Switch Network");

      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainDetails.chainId }],
      });
      setLoading(false);
    } catch {
      // This error code indicates that the chain has not been added to MetaMask.
      console.log("Catch Happening");
      setLoading(true);
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [chainDetails],
      });
      setLoading(false);

      // handle other "switch" errors
    }
  }

  const handleInputFocus = () => {
    const priceInputContainer = document.querySelector("#price-container-1");
    priceInputContainer.classList.add("active");
  };

  const handleInputBlur = () => {
    const priceInputContainer = document.querySelector("#price-container-1");
    priceInputContainer.classList.remove("active");
  };

  const renderActionButton = () => {
    if (noEth) {
      return (
        <button
          className="swap-btn-container install-metamask-btn"
          onClick={() => {
            // Logic to handle MetaMask installation
          }}
        >
          <a style={{color: 'black'}} href="https://metamask.io/">Install MetaMask</a>
        </button>
      );
    } else if (isCompatible && accounts) {
      return (
        <button
          className="swap-btn-container swap-active"
          disabled={!tokenContractAddress1 || !tokenContractAddress2 || !token1Value || !token2Value || loading || localLoading}
          onClick={() => {
            handleSwap();
          }}
        >
          {loading ? (
                      <Blocks
                      height="80"
                      width="80"
                      color="#4fa94d"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      visible={true}
                      />
          ) : (
            <h3>Swap</h3>
          )}
        </button>
      );
    } else if (isCompatible && !noEth) {
      return (
        <button
          className="swap-btn-container swap-connect"
          onClick={() => {
            connectWalletHandler();
          }}
        >
          <h3>Connect Wallet</h3>
        </button>
      );
    } else if (!isCompatible) {
      return (
        <button
          className="swap-btn-container swap-switch"
          onClick={() => {
            switchNetwork();
          }}
        >
          <h3>Switch Network</h3>
        </button>
      );
    }
  
    return null; // Return null for other cases where no button is rendered
  };

  return (
    <div className="orc-body">
      <div className="swap-title-container">
        <h3>tQANX - Token Swap </h3>
        <div>
          <button className="settings-button" onClick={() => {setShowSettings(!showSettings)}}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="MenuButton__Icon-sc-7e635c1d-0 hWamdH"><path d="M20.83 14.6C19.9 14.06 19.33 13.07 19.33 12C19.33 10.93 19.9 9.93999 20.83 9.39999C20.99 9.29999 21.05 9.1 20.95 8.94L19.28 6.06C19.22 5.95 19.11 5.89001 19 5.89001C18.94 5.89001 18.88 5.91 18.83 5.94C18.37 6.2 17.85 6.34 17.33 6.34C16.8 6.34 16.28 6.19999 15.81 5.92999C14.88 5.38999 14.31 4.41 14.31 3.34C14.31 3.15 14.16 3 13.98 3H10.02C9.83999 3 9.69 3.15 9.69 3.34C9.69 4.41 9.12 5.38999 8.19 5.92999C7.72 6.19999 7.20001 6.34 6.67001 6.34C6.15001 6.34 5.63001 6.2 5.17001 5.94C5.01001 5.84 4.81 5.9 4.72 6.06L3.04001 8.94C3.01001 8.99 3 9.05001 3 9.10001C3 9.22001 3.06001 9.32999 3.17001 9.39999C4.10001 9.93999 4.67001 10.92 4.67001 11.99C4.67001 13.07 4.09999 14.06 3.17999 14.6H3.17001C3.01001 14.7 2.94999 14.9 3.04999 15.06L4.72 17.94C4.78 18.05 4.89 18.11 5 18.11C5.06 18.11 5.12001 18.09 5.17001 18.06C6.11001 17.53 7.26 17.53 8.19 18.07C9.11 18.61 9.67999 19.59 9.67999 20.66C9.67999 20.85 9.82999 21 10.02 21H13.98C14.16 21 14.31 20.85 14.31 20.66C14.31 19.59 14.88 18.61 15.81 18.07C16.28 17.8 16.8 17.66 17.33 17.66C17.85 17.66 18.37 17.8 18.83 18.06C18.99 18.16 19.19 18.1 19.28 17.94L20.96 15.06C20.99 15.01 21 14.95 21 14.9C21 14.78 20.94 14.67 20.83 14.6ZM12 15C10.34 15 9 13.66 9 12C9 10.34 10.34 9 12 9C13.66 9 15 10.34 15 12C15 13.66 13.66 15 12 15Z" fill="white"></path></svg>
          </button>
        </div>
      </div>

      <div className="price-input-container" id="price-container-1">
        <div className="price-subtitle">
          <h6>You Pay</h6>
        </div>

        <div className="second-row">
          <input
            className="price-input"
            type="number"
            placeholder="0"
            disabled={!isCompatible || loading || noEth}
            value={token1Value}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={(e) => { delayedHandleInputChange(e)}}
            onInput={(e) => {
              e.preventDefault(); // Prevent default behavior to avoid moving the cursor
            }}
            min="0"
          />
          <div className="asset-container">
            {tokenContractAddress1 && verifiedTokenInfo1 && tokenContractAddress1? (
              <button
                className="asset-button"
                onClick={openModal1}
              >
                <div className="token-icon">{verifiedTokenInfo1.image? <img src={verifiedTokenInfo1.image} alt="image" /> : <img src={questionToken} alt="image" />}</div>
                {verifiedTokenInfo1.symbol}
              </button>
            ) : (
              <button className="select-button" onClick={openModal1}>
                {" "}
               Select Token
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="arrow-container">
        <button
          className="arrow-button"
          onClick={
            tokenContractAddress1 && tokenContractAddress2 && handleSwapTokens
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#FFFFFF"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <polyline points="19 12 12 19 5 12"></polyline>
          </svg>
        </button>
      </div>

      <div className="price-input-container" id="price-container-2">
        <div className="price-subtitle">
          <h6>You Receive</h6>
        </div>

        <div className="second-row">
        {localLoading &&
                      <Grid 
                      visible={true}
                      height="30"
                      width="30"
                      color="rgba(25, 100, 213, 0.9)"
                      ariaLabel="grid-loading"
                      radius="12.5"
                      wrapperStyle={{position: 'absolute',marginLeft: '20px'}}
                      wrapperClass="grid-wrapper"
                      />               
                    }


                      <input
                      className="price-input"
                      type="number"
                      disabled={true}
                      placeholder="0"
                      value={token2Value}
                      style={localLoading? {opacity: '0'} : {}}
                      min="0"
                      onInput={(e) => {
                        e.target.value =
                          !!e.target.value && Math.abs(e.target.value) >= 0
                            ? Math.abs(e.target.value)
                            : null;
                      }}
                    />

        
          <div className="asset-container">
            {tokenContractAddress2 ? (
              tokenContractAddress2 && verifiedTokenInfo2 && tokenContractAddress2 ? (
                <button
                  className="asset-button"
                  onClick={openModal2}
                >
                <div  className="token-icon">{verifiedTokenInfo2.image? <img src={verifiedTokenInfo2.image} alt="image"/> : <img src={questionToken} alt="image" />}</div>
                  {verifiedTokenInfo2.symbol}
                </button>
              ) : (
                <button className="select-button" onClick={openModal2}>
                {" "}
               Select Token
              </button>
              )
            ) : (
              <button className="select-button" onClick={openModal2}>
                {" "}
               Select Token
              </button>
            )}
          </div>
        </div>
      </div>

      {renderActionButton()}

      <div
        className={`overlay ${modal1IsOpen ? "open" : ""}`}
        onClick={closeModal1}
      ></div>

      <div className={`modal ${modal1IsOpen ? "open" : ""}`}>
        <ChooseTokenModal
          isOpen={modal1IsOpen}
          closeModal={closeModal1}
          setTokenContractAddress={setTokenContractAddress1}
          setTokenName={setTokenName1}
          currentSelectedToken={tokenContractAddress1}
          selectedTokenInOtherField={tokenContractAddress2}
          handleSwapTokens={handleSwapTokens}
          clearTokenValues={clearTokenValues}
          setVerifiedTokenInfo={setVerifiedTokenInfo1}
          verifiedTokenInfo={verifiedTokenInfo1}
        />
      </div>

      <div
        className={`overlay ${modal2IsOpen ? "open" : ""}`}
        onClick={closeModal2}
      ></div>

      <div className={`modal ${modal2IsOpen ? "open" : ""}`}>
        <ChooseTokenModal
          isOpen={modal2IsOpen}
          closeModal={closeModal2}
          setTokenContractAddress={setTokenContractAddress2}
          setTokenName={setTokenName2}
          currentSelectedToken={tokenContractAddress2}
          selectedTokenInOtherField={tokenContractAddress1}
          handleSwapTokens={handleSwapTokens}
          clearTokenValues={clearTokenValues}
          setVerifiedTokenInfo={setVerifiedTokenInfo2}
          verifiedTokenInfo={verifiedTokenInfo2}
        />
      </div>


      <div className={`modal ${showSettings ? "open" : ""}`}>
          <SettingsModal 
          slippage={slippage}
          setSlippage={setSlippage}
          closeModal={closeSettings}
          isOpen={showSettings}
          />
      </div>
    </div>
  );
};

export default SwapModal;
