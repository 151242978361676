import React from 'react'

function CheckComponent() {
  return (
    <span className="checkmark">
    <div className="checkmark_stem"></div>
    <div className="checkmark_kick"></div>
    </span>
  )
}

export default CheckComponent